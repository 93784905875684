.entity-table-control-panel-container {
    background-color: #f6f6f6;
    padding: .8rem 2rem;

    display: flex;
    flex-direction: row;

    border-bottom: 1px solid #dcdcdc;
}

.entity-table-control-panel-header {
    font-weight: 800;
    font-size: 16px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.entity-table-control-panel-header-counter {
    color: #687078;
    font-weight: 400;
    padding-left: .3rem;
    padding-right: .5rem;
}

.entity-table-control-panel-spanner {
    flex-grow: 1;
}

.entity-table-control-panel-btn-group {
    display: flex;
}

.entity-table-control-panel-btn-group:not(:last-child) {
    margin-right: 5px;
}

.entity-table-control-panel-refresh-label {
    color: #687078;
    font-weight: 400;
    font-size: 14px;

    display: flex;
    justify-content: center;
    align-items: center;

    padding-right: .5rem;
}

/*--------------------------------*/
.settings-modal-container-header {
    font-weight: 800;
    font-size: 18px;
    background-color: #f6f6f6;

    padding: 1rem 2rem;
}

.settings-modal-container-body {
    padding: .8rem 2rem;

    border-top: 1px solid #eaeded;
    border-bottom: 1px solid #eaeded;

    display: grid;
    grid-template-columns: 1fr 1fr;
}

.settings-modal-container-body .column-header {
    font-weight: 600;
    font-size: 16px;
    padding-bottom: .8rem;
}

.settings-modal-container-body .setting {
    padding: .4rem 0;
}

.settings-modal-container-footer {
    padding: .5rem 2rem;

    display: flex;
    flex-direction: row-reverse;
}

.vertical-radiogroup {
    display: flex;
    flex-direction: column;
}
