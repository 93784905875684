.login-page-workspace {
    height: inherit;
    box-sizing: border-box;
    align-items: center;
}

.login-page-workspace-block {
    min-width: 550px;
    max-width: 550px;
    width: 550px;
    margin-top: 8%;
}

.login-page-form-container {
}

.login-page-action-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    margin-top: 16px;
}

.login-page-error-container {
    min-width: 550px;
    max-width: 550px;
    width: 550px;
    box-sizing: border-box;
    padding: 1rem 2rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    border: 1px solid rgb(229, 44, 91);
    border-radius: 20px;

    color: rgb(229, 44, 91);
}

.login-page-error-container > .text {
    padding-left: 8px;
}

.platform-desc {
    color: #687078;
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
}