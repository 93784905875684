:root {
    --kc-item-table-boredr-color: rgba(104, 112, 120, 0.4);
}
table.entity-registry-table > tbody > tr > td.kc-document-preview-row {
    padding: 0;
}

.kc-document-preview-container {
    padding: 16px 0 8px;
    flex-grow: 1;
}

.kc-document-preview-container .filed-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

table.kc-items-table {
    border-collapse: collapse;
}

table.kc-items-table > thead > tr > th {
    background-color: #fff;
    border: 0 solid var(--kc-item-table-boredr-color);
    border-bottom: 1px solid var(--kc-item-table-boredr-color);

    padding: .8rem 32px;
    position: relative;
}

table.kc-items-table > thead > tr > th.center {
    text-align: center;
}

table.kc-items-table > thead > tr > th:not(:first-child):before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 25%;
    height: 50%;
    border-left: 1px solid var(--kc-item-table-boredr-color);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

table.kc-items-table > tbody > tr > td {
    background-color: #fff;
    border: 0 solid var(--kc-item-table-boredr-color);

    padding: .8rem 32px;
}

table.kc-items-table > tbody > tr > td:not(:last-child) {
    border-right: 1px solid var(--kc-item-table-boredr-color);
}

table.kc-items-table > tbody > tr > td.numeric_cell {
    text-align: right;
}

table.kc-items-table > tbody > tr > td.has_issue {
    color: #D8000C;
    background-color: #FFD2D2;
}

table.kc-items-table > tbody > tr > td.has_warning {
    color: #9F6000;
    background-color: #FEEFB3;
}

table.kc-items-table .group-item {
    font-weight: 500;
    font-size: 15px;
    padding: 8px 2rem;
}
