.main-menu-action-container {
    padding: 0.8rem 2rem;
}

.main-menu-action-container > .platform-desc {
    color: #687078;
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
    text-align: center;

    padding-left: 16px;
}