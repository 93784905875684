.pto-report-summary-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px
}

.pto-report-summary-table .header {
    background-color: #f6f6f6;

    border-right: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    padding: .8rem 32px;
    text-align: left;

    position: relative;

    font-weight: bold;
}

.pto-report-summary-table .header.center {
    text-align: center;
}

.pto-report-summary-table > tbody {
    background-color: white;
}

.pto-report-summary-table > tbody tr {
    line-height: 20px;
}

.pto-report-summary-table > tbody tr:last-child td {
    border-bottom: unset;
}

.pto-report-summary-table > tbody tr td {
    border-bottom: 1px solid #eaeded;
    padding: .4rem 2rem;
}

.pto-report-summary-table > tbody tr td.report_numeric_cell {
    text-align: right;
}

.issue_error {
    color: #D8000C;
    background-color: #FFD2D2;
}

.pto-report-summary-table > tbody tr td.issue_warning {
    color: #9F6000;
    background-color: #FEEFB3;
}

.summary-table-header-container {
    margin-top: 12px;
    margin-bottom: 8px;
}

.summary-table-header-container .document-chips-container {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
}

.pto-report-summary-table .header.collapsed {
    display: table-cell;
    vertical-align: top;

    background-color: #f6f6f6;
    border-left: 1px solid #dcdcdc;
    border-bottom: 0;
    padding: 0.8rem 0.4rem;
}


.rotated_document_name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    writing-mode: vertical-rl;
}