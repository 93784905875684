table.entity-registry-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px
}

table.entity-registry-table > tbody {
    background-color: white;
}

table.entity-registry-table > thead {
    background-color: #f6f6f6;
}

table.entity-registry-table > thead > tr > th {
    border-bottom: 1px solid #dcdcdc;
    padding: .8rem 32px;
    text-align: left;

    position: relative;
}

table.entity-registry-table > thead > tr > th:not(:first-child):before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 25%;
    height: 50%;
    border-left: 1px solid #dcdcdc;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

table.entity-registry-table > tbody > tr {
    line-height: 20px;
}

table.entity-registry-table > tbody > tr:last-child > td {
    border-bottom: unset;
}

table.entity-registry-table > tbody > tr > td {
    border-bottom: 1px solid #eaeded;
    padding: .4rem 2rem;
}

table.entity-registry-table > tbody > tr.selected > td:first-child {
    position: relative;
}

table.entity-registry-table > tbody > tr.selected > td:first-child:before {
    position: absolute;
    content: ' ';
    background-color: #ec7211;
    height: 8px;
    width: 8px;
    left: 12px;
    top: calc(100% / 2 - 4px);
    border-radius: 4px;
}