.add-button-container {
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px dashed grey;
}

.pto-item-table tbody tr td.item-editor-cell {
    padding: 4px 4px;
}

.object-total-price-container {
    font-size: 13px;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    margin-top: -5px;
    margin-bottom: 15px;
}
