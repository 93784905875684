.main-menu-container {
    width: 280px;
    height: 100%;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;

    background-color: #fff;
    box-shadow: 0 1px 1px 0 rgba(0,28,36,.3),1px 1px 1px 0 rgba(0,28,36,.15),-1px 1px 1px 0 rgba(0,28,36,.15);
}

.menu-leaf-item {
    margin: 1rem 0;
    list-style: none;
    cursor: pointer;
    font-weight: 400;
}
.menu-leaf-item a {
    text-decoration: none;
    color: black;
}

.menu-leaf-item a:hover {
    color: #ec7211;
}

.menu-leaf-item .selected-item {
    font-weight: 700;
    color: #ec7211;
}
.main-menu-container ul {
    padding: 0 2rem;
    box-sizing: border-box;
}
