body {
  background-color: #efefef;
}

html, body {
  height: 100%;
}

#root {
  height: 100%;
}

.notification-container {
  max-width: 400px;
}