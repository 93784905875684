.overview_block_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    grid-auto-rows: 80px;
}

.overview_block {
    min-width: 250px;
}

.overview_block .header {
    font-weight: 200;
    font-size: 14px;
    margin-bottom: .8rem;
}

.overview_block .value {
    font-weight: 300;
    font-size: 20px;
}

/* region report-overview-invoices-grid */
.report-overview-invoices-grid {
    display: grid;
    grid-template-columns: min-content minmax(150px, 400px) min-content max-content minmax(150px, 1fr);
    column-gap: 16px;
    row-gap: 10px;
    align-items: center;

    font-weight: 300;
    font-size: 18px;
}

.report-overview-invoices-grid .desc {
    color: #687078;
    font-weight: 200;
    font-size: 14px;
}

.report-overview-invoices-grid .name {
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.report-overview-invoices-grid .value {
    text-align: right;
    font-size: 18px;
}

.report-overview-invoices-grid .comment {
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.report-overview-invoices-grid .total_issue {
    grid-column: 4 / 6;
}

/* endregion */
