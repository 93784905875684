.overview_lists_wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(750px, 1fr));
    column-gap: 32px;
}

.overview_list_container {
    overflow: hidden;
}

.overview_list_container .header {
    font-weight: 200;
    font-size: 18px;

    margin-top: 0.8rem;
    margin-bottom: .8rem;
}

/* region report_overview_kc_grid */
.report_overview_kc_grid {
    display: grid;
    grid-template-columns: min-content minmax(150px, 400px) min-content max-content max-content;
    column-gap: 16px;
    row-gap: 10px;
    align-items: center;

    font-weight: 300;
    font-size: 18px;
}

.report_overview_kc_grid_desc {
    color: #687078;
    font-weight: 200;
    font-size: 14px;
}

.report_overview_kc_grid_name {
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.report_overview_kc_grid_value {
    text-align: right;
    font-size: 18px;
}

/* endregion */