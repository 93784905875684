.label-container {
    display: inline-block;
    border-radius: 4px;
}

.label-container.success {
    color: rgb(7, 163, 0);
    background-color: rgba(59, 201, 53, 0.25);
}

.label-container.error {
    color: #D8000C;
    background-color: #FFD2D2;
}

.label-container.info {
    color: #027bf3;
    background-color: rgba(2,123,243,0.20);
}

.label-container > .text {
    line-height: 20px;
    margin: 0 12px;
}