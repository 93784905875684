.workspace-container {
    width: 100%;
    height: 100%;

    overflow-y: scroll;

    flex-grow: 1;
}

.workspace {
    padding: 15px;

    display: flex;
    flex-direction: column;
}

.workspace.align-center {
    align-items: center;
}

.workspace-block {
    width: 100%;
    min-width: 800px;
    box-sizing: content-box;

    border-top: 1px solid #e0e0e0;
    border-radius: 2px;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14),0 2px 1px -1px rgba(0,0,0,0.12),0 1px 3px 0 rgba(0,0,0,0.2);

    background-color: #f6f6f6;
}

.workspace .workspace-block {
    margin-bottom: 15px;
}

.workspace .workspace-block:last-child {
    margin-bottom: 0;
}

.workspace > .header {
    font-size: 1.5rem;
    font-weight: 600;

    margin-bottom: 16px;
}

.workspace > .second-header {
    margin-bottom: 8px;
    display: flex;

    font-size: 1.2rem;
    font-weight: 400;

    align-items: center;
}

.workspace > .second-header > .spacer {
    flex-grow: 1;
}

.workspace-block > .header {
    font-weight: 800;
    font-size: 1rem;

    padding: 0.8rem 2rem;

    border-bottom: 1px solid #eaeded;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.workspace-block > .body {
    background-color: #ffffff;
    padding: 1rem 2rem;
}

.filed-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.filed-container.not-align {
    align-items: flex-start;
}

.filed-container:not(:last-child) {
    margin-bottom: 8px;
}

.filed-container > label {
    min-width: 250px;
}

.filed-container.small > label {
    min-width: 150px;
}

.filed-container > .field-control {
    min-width: 300px;
}

.filed-container.fill > .field-control{
    flex-grow: 1;
}

.filed-container > span.field-control{
    min-width: 300px;
    padding: 8px 0;
}