.empty_pto_item_container {
    display: flex;
    flex-direction: column;

    align-items: center;

    padding: 2rem 2rem;
}

.empty_pto_item_container div:not(:last-child) {
    margin-bottom: 16px;
}

.empty_pto_item_container .title {
    font-weight: 600;
}

.editor-loader-container {
    display: flex;

    flex-direction: row;
    justify-content: center;

    padding-top: 100px;
}

.invoice-tab-total-container {
    font-size: 13px;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    margin-top: -5px;
    margin-bottom: 15px;
}